import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, Typography, ListItemIcon, IconButton } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import styles from "../../Common/Dashboard.module.css"; // Create a CSS module for styling



export default function Sidebar({ tabs }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();


  return (
    <div className={styles.sidebar} style={{ width: isCollapsed ? "80px" : "250px" }}>
      <div className={styles.header} style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          <MenuOutlinedIcon />
        </IconButton>
        {!isCollapsed && (
          <>
            <img
              alt="profile-user"
              width="100px"
              height="100px"
              src="/aspatt-lgo-circle.png"
              style={{ cursor: "pointer", borderRadius: "50%", margin: '10px 0' }}
            />
            <Typography
              variant="h6"
              color="textPrimary"
              fontWeight="bold"
            >
              ASPATT HEALTHCARE
            </Typography>
          </>
        )}
      </div>
      <List>
        {tabs.map((tab, index) => (
          <ListItem 
            button 
            key={index} 
            onClick={() => navigate(tab.redirect)}
            className={styles.listItem}
          >
            {tab.icon && <ListItemIcon>{tab.icon}</ListItemIcon>}
            {!isCollapsed && <ListItemText primary={tab.title} />}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

