import React from "react";
import Dashboard from "../Common/Dashboard";
import Sidebar from "../Common/Sidebar/Sidebar";
import styles from "../Common/Dashboard.module.css";
import Topbar from "../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

export const tabs = [
  { title: "Home", redirect: "/dashboard/admin/" },
  { title: "List of Doctors", redirect: "/dashboard/admin/doc-list" },
  { title: "List of Hospitals", redirect: "/dashboard/admin/hospital-list" },
  { title: "Verify User", redirect: "/dashboard/admin/verify-user" },
  { title: "Book Appointment List", redirect: "/dashboard/admin/Book_Appointment_list" },
  { title: "Enquiry Form List", redirect: "/dashboard/admin/Enqiry_Form_list" },
  { title: "Feedbacks", redirect: "/dashboard/admin/feedbacks" },
  { title: "List of Staffs", redirect: "/dashboard/admin/staff-list" },
  { title: "Generate Stats", redirect: "/dashboard/admin/generate-stats" },

];

// export default function AdminDash() {
//   return (
//     <div className={styles.containerArea}>
//       <Dashboard tabs={tabs} />
//     </div>
//   );
// }

export default function AdminDash() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content"></main>
          <div className={styles.container}>
            <Sidebar tabs={tabs} />
            <div className={styles.mainContent}>
              <Topbar />
              <div className={styles.contentArea}>
                <Dashboard tabs={tabs} />
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
