import React, { useEffect, useRef } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import "./VerticalCarousel.css";

const VerticalCarousel = () => {
  const carouselRef1 = useRef(null);
  const carouselRef2 = useRef(null);

  const dummyData = [
    {
      id: 1,
      image: "/image130.png",
      heading: "Gynecology",
      paragraph: "Best Eye Specialists With 10+ years of experience.",
    },
    {
      id: 2,
      image: "/image130.png",
      heading: "Proctology",
      paragraph: "Best Eye Specialists With 10+ years of experience.",
    },
    {
      id: 3,
      image: "/image130.png",
      heading: "Ophthalmology",
      paragraph: "Best Eye Specialists With 10+ years of experience.",
    },
    {
      id: 4,
      image: "/image130.png",
      heading: "Laparoscopy",
      paragraph:
        "Best General Surgeons With Expertise in Laparoscopic Surgeries.",
    },
  ];

  // Regular scrolling logic for carousel 1 (upward)
  useEffect(() => {
    const carousel1 = carouselRef1.current;
    let scrollAmount1 = 0;

    const scrollCards1 = () => {
      scrollAmount1 += 1;
      carousel1.scrollTop = scrollAmount1;

      if (scrollAmount1 >= carousel1.scrollHeight - carousel1.clientHeight) {
        scrollAmount1 = 0; // Reset to the top
      }
    };

    const interval1 = setInterval(scrollCards1, 50); // Adjust speed as needed

    return () => clearInterval(interval1);
  }, []);

  // Reverse scrolling logic for carousel 2 (downward)
  useEffect(() => {
    const carousel2 = carouselRef2.current;

    // Set the initial scroll position to the bottom
    carousel2.scrollTop = carousel2.scrollHeight - carousel2.clientHeight;
    let scrollAmount2 = carousel2.scrollTop;

    const scrollCards2 = () => {
      scrollAmount2 -= 1;
      carousel2.scrollTop = scrollAmount2;

      if (scrollAmount2 <= 0) {
        scrollAmount2 = carousel2.scrollHeight - carousel2.clientHeight; // Reset to the bottom
      }
    };

    const interval2 = setInterval(scrollCards2, 50); // Adjust speed as needed

    return () => clearInterval(interval2);
  }, []);

  return (
    <div className="main-section">
      {/* Left side content */}
      <div className="left-side">
        <div className="left-content">
          <h2 className="header1">For Enquiry Connect with us on</h2>
          <div className="phone-section">
            <FaPhoneAlt className="phone-icon" />
            <span className="phone-number">0900-78601</span>
          </div>
          <button className="btn">Book Appointment</button>
        </div>
      </div>

      {/* Right side carousels */}
      <div className="right-side">
        <div className="carousel-container1" ref={carouselRef1}>
          <div className="carousel">
            {dummyData.map((card) => (
              <div className="card" key={card.id}>
                <img
                  src={card.image}
                  alt={card.heading}
                  className="card-image"
                />
                <h3>{card.heading}</h3>
                <p>{card.paragraph}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="carousel-container2" ref={carouselRef2}>
          <div className="carousel">
            {dummyData.map((card) => (
              <div className="card" key={card.id}>
                <img
                  src={card.image}
                  alt={card.heading}
                  className="card-image"
                />
                <h3>{card.heading}</h3>
                <p>{card.paragraph}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
