import React, { useEffect, useState } from "react";
import styles from "./AddNew.module.css";
import Navbar from "../../../Navbar/Navbar";
import { useSearchParams } from "react-router-dom";
import api from "../../../../api"; // Assuming this is your custom API utility
import { useAuth } from "../../../../AuthContext";
import { departments } from "../../Doctor/doctorDepartments";
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const options = [
  "Select User Type",
  "Diagnostics",
  "Pharmacy",
  "Hospital",
  "Doctor",
  "NGO",
  "Home Care",
  "Laboratory",
  "Ambulance",
  "Patient",
];

export default function AddNew() {
  const { setLoader, setAlert, setAlertMsg, setAlertType } = useAuth();
  const [searchParams] = useSearchParams();

  const [userType, setUserType] = useState("");
  const [fname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [workDays, setWorkDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState({});
  const [speciality, setSpeciality] = useState("");
  const [fee, setFee] = useState("");
  const [department, setDepartment] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = searchParams.get("uid");
        const response = await api.getfindUser(uid);
        const userData = response.data;

        console.log("output from backend:", userData);

        // Populate state with fetched user data
        setUserType(userData.userType || "");
        setFullName(userData.fname || "");
        setEmail(userData.email || "");
        setDepartment(userData.department || "");
        setSpeciality(userData.speciality || "");
        setFee(userData.fee || "");
        setWorkDays(userData.workDays || []);

        // Set initial time slots if available
        const initialTimeSlots = {};
        (userData.workDays || []).forEach((day) => {
          initialTimeSlots[day] = userData.timeSlots[day] || "";
        });
        setTimeSlots(initialTimeSlots);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchParams]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setWorkDays((prevSelectedDays) => [...prevSelectedDays, value]);
    } else {
      setWorkDays((prevSelectedDays) => prevSelectedDays.filter((day) => day !== value));
      // Remove time slot when the day is unchecked
      setTimeSlots((prevSlots) => {
        const updatedSlots = { ...prevSlots };
        delete updatedSlots[value];
        return updatedSlots;
      });
    }
  };

  const handleTimeSlotChange = (day, event) => {
    const { value } = event.target;
    setTimeSlots((prevSlots) => ({
      ...prevSlots,
      [day]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uid = searchParams.get("uid");

    const workDaysWithTimes = workDays.map((day) => ({
      day,
      time: timeSlots[day] || "",
    }));
    
    try {
      setLoader(true);
      const dataToSend = {
        uid,
        userType, // Include userType
        workDays: workDaysWithTimes,
        email,
        department,
        speciality,
        fee,
      };
  
      // Log the JSON data before sending
      console.log("Data to send:", dataToSend);
      
      const res = await api.verify(dataToSend);
      if (res.data.error) {
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        setAlertMsg(res.data.msg);
        setAlertType("success");
        setAlert(true);
      }
    } catch (error) {
      setAlertMsg(error?.response?.data?.errorMsg || "An Error Occurred!");
      setAlert(true);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.formContainer}>
        <form className={styles.form} validate="true" onSubmit={handleSubmit}>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="usertype">User Type</label>
            </div>
            <div className={styles.col75}>
              <select
                className={styles.input}
                id="usertype"
                name="usertype"
                value={userType}
                onChange={(event) => setUserType(event.target.value)}
                required
              >
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="fullname">Full Name</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="fullname"
                name="fullname"
                value={fname}
                onChange={(e) => setFullName(e.target.value)}
                required
                disabled={true}
                
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="mobile">Mobile Number</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="text"
                id="mobile"
                name="mobile"
                value={""}
                required
                disabled={true}
              />
            </div>
          </div>
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <label htmlFor="email">Email</label>
            </div>
            <div className={styles.col75}>
              <input
                className={styles.input}
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          {userType === "Doctor" && (
            <>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="department">Department</label>
                </div>
                <div className={styles.col75}>
                  <select
                    className={styles.input}
                    id="department"
                    name="department"
                    value={department}
                    onChange={(event) => setDepartment(event.target.value)}
                    required
                  >
                    {departments.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="speciality">Speciality</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="text"
                    id="speciality"
                    name="speciality"
                    value={speciality}
                    onChange={(event) => setSpeciality(event.target.value)}
                    placeholder="Speciality ..."
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="fee">Fee</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="number"
                    id="fee"
                    name="fee"
                    value={fee}
                    onChange={(event) => setFee(event.target.value)}
                    placeholder="Appointment fee..."
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
            </>
          )}
          <div className={styles.rrow}>
            <div className={styles.col25}>
              <p className={styles.workdaysLabel}>Work Days</p>
            </div>
            <div className={styles.col75}>
              <div className={styles.checkboxContainer}>
                <div className={styles.checkboxRow}>
                  {days.map((day) => (
                    <div key={day} className={styles.checkboxItem}>
                      <label htmlFor={`workday-${day}`}>
                        <input
                          type="checkbox"
                          name="workday"
                          id={`workday-${day}`}
                          value={day}
                          checked={workDays.includes(day)}
                          onChange={handleCheckboxChange}
                        />
                        {day}
                        {workDays.includes(day) && (
                          <input
                            type="text"
                            className={styles.timeInput}
                            value={timeSlots[day] || ""}
                            onChange={(event) => handleTimeSlotChange(day, event)}
                            placeholder="Time..."
                          />
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rrow}>
            <button type="submit" className={styles.submitBtn}>
              Verify User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

