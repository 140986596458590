import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

const consultationsData = [
  {
    id: 1,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Unlimited Free consultations",
    description: "Get unlimited free consultations with all doctors 24x7",
  },
  {
    id: 2,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Free For All Health Problems",
    description: "Consult with any doctor from 18 departments at any time",
  },
  {
    id: 3,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Free For All Family Members",
    description: "Free consultations for upto 6 family members",
  },
  {
    id: 4,
    image: "medical_consultation (1).png", // replace with your image path
    title: "Unlimited Free consultations",
    description: "Get unlimited free consultations with all doctors 24x7",
  },
];

const Consultations = () => {
  return (
    <Box sx={{ padding: "40px 20px" }}>
      <Typography
        variant="h2" // Increased font size
        sx={{
          fontWeight: "bold",
          textDecoration: "underline",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Give The Care Your Family Deserves
      </Typography>
      <Typography
        variant="h4" // Increased font size
        sx={{ textAlign: "center", marginBottom: "40px" }}
      >
        Unlimited Consultations with top Doctors for 6 family members in 22+
        specialities and 16 languages
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {consultationsData.map((consultation) => (
          <Grid item key={consultation.id}>
            <Card
              sx={{
                width: 250,
                textAlign: "center",
                marginLeft: "20px",
                marginRight: "20px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)", // Added box shadow
                transition: "transform 0.3s ease", // Added transition for hover effect
                "&:hover": {
                  transform: "scale(1.05)", // Scale effect on hover
                },
              }}
            >
              {" "}
              {/* Fixed width for cards */}
              <CardMedia
                component="img"
                height="140"
                image={consultation.image}
                alt={consultation.title}
                sx={{ objectFit: "cover" }} // Ensure the image fits well within the card
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4" // Increased font size for title
                  component="div"
                  sx={{ fontWeight: "bold" }} // Make title bold
                >
                  {consultation.title}
                </Typography>
                <Typography
                  variant="body1" // Increased font size for description
                  color="text.secondary"
                  sx={{ fontSize: "1.3rem" }} // Adjust font size as needed
                >
                  {consultation.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Consultations;
