import React, { useEffect, useState } from "react";
import styles from "./EditUserForm.module.css";
import { useSearchParams } from "react-router-dom";
import api from "../../../../api"; // Assuming this is your custom API utility
import { useAuth } from "../../../../AuthContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { departments } from "../../Doctor/doctorDepartments";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export default function EditUserForm() {
  const { setLoader, setAlert, setAlertMsg, setAlertType } = useAuth();
  const [searchParams] = useSearchParams();

  const [userType, setUserType] = useState("");
  const [fname, setFullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [workDays, setWorkDays] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [fee, setFee] = useState("");
  const [department, setDepartment] = useState("");
  // const [timeSlots, setTimeSlots] = useState({});
  const [status, setStatus] = useState("deactive"); // New state for status
  // const [ profilePicture, setProfilePicture] = useState(null);
  // const [profilePreview, setProfilePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uid = searchParams.get("uid");
        const response = await api.findUserWithStatus(uid, status);
        const userData = response.data;

        // Populate state with fetched user data
        setUserType(userData.userType || "");
        setFullName(userData.fname || "");
        setMobile(userData.mobile || "");
        setEmail(userData.email || "");
        setWorkDays(userData.workDays || []);
        setStatus(userData.status || "deactive");
        setFee(userData.fee || "");
        setDepartment(userData.department || "");
        setSpeciality(userData.speciality || "");
         // Populate status

        // const initialTimeSlots = {};
        // (userData.workDays || []).forEach((day) => {
        //   initialTimeSlots[day] = userData.timeSlots[day] || "";
        // });
        // setTimeSlots(initialTimeSlots);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [searchParams, status]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      // Add the day to workDays with an empty time field
      setWorkDays((prevWorkDays) => [
        ...prevWorkDays,
        { day: value, time: "" },
      ]);
    } else {
      // Remove the day from workDays
      setWorkDays((prevWorkDays) =>
        prevWorkDays.filter((dayObj) => dayObj.day !== value)
      );
      // setWorkDays((prevSelectedDays) => prevSelectedDays.filter((day) => day !== value));
      // setTimeSlots((prevSlots) => {
      //   const updatedSlots = { ...prevSlots };
      //   delete updatedSlots[value];
      //   return updatedSlots;
      // });
    }
  };

  const handleTimeSlotChange = (day, event) => {
    const { value } = event.target;
    setWorkDays((prevWorkDays) =>
      prevWorkDays.map((dayObj) =>
        dayObj.day === day ? { ...dayObj, time: value } : dayObj
      )
    );
    // setTimeSlots((prevSlots) => ({
    //   ...prevSlots,
    //   [day]: value,
    // }));
  };

  // const handleProfilePictureChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setProfilePicture(file);
  //     setProfilePreview(URL.createObjectURL(file));
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const uid = searchParams.get("uid");

    // const workDaysWithTimes = workDays.map((day) => ({
    //   day,
    //   time: timeSlots[day] || "",
    // }));

    try {
      setLoader(true);

      // Create a JSON object to send
      const dataToSend = {
        status,
        userType, // Include userType
        workDays, //:workDaysWithTimes
        email,
      };

      // Log the JSON data before sending
      console.log("Data to send:", dataToSend);

      // Make the API call with JSON
      const res = await api.userUpdate(uid, dataToSend);

      if (res.data.error) {
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        setAlertMsg(res.data.msg);
        setAlertType("success");
        setAlert(true);
      }
    } catch (error) {
      setAlertMsg(error?.response?.data?.errorMsg || "An Error Occurred!");
      setAlert(true);
    } finally {
      setLoader(false);
    }
  };


  const [theme, colorMode] = useMode();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
        <div className={styles.container}>
          <div className={styles.formContainer}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="usertype">User Type</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="text"
                    id="usertype"
                    name="usertype"
                    value={userType}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="fullname">Full Name</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="text"
                    id="fullname"
                    name="fullname"
                    value={fname}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label className={styles.label} htmlFor="mobile">
                    Mobile Number
                  </label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={mobile}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="email">Email</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="profilePicture">Profile Picture</label>
                </div>
            </div>
          {userType === "Doctor" && (
            <>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="department">Department</label>
                </div>
                <div className={styles.col75}>
                  <select
                    className={styles.input}
                    id="department"
                    name="department"
                    value={department}
                    onChange={(event) => setDepartment(event.target.value)}
                    required
                  >
                    {departments.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="speciality">Speciality</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="text"
                    id="speciality"
                    name="speciality"
                    value={speciality}
                    onChange={(event) => setSpeciality(event.target.value)}
                    placeholder="Speciality ..."
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <label htmlFor="fee">Fee</label>
                </div>
                <div className={styles.col75}>
                  <input
                    className={styles.input}
                    type="number"
                    id="fee"
                    name="fee"
                    value={fee}
                    onChange={(event) => setFee(event.target.value)}
                    placeholder="Appointment fee..."
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
            </>
          )}

              <div className={styles.rrow}>
                <div className={styles.col25}>
                  <p className={styles.workdaysLabel}>Work Days</p>
                </div>
                <div className={styles.col75}>
                  {days.map((day) => (
                    <div key={day} className={styles.checkboxItem}>
                      <label className={styles.label}>
                        <input
                          type="checkbox"
                          value={day}
                          checked={workDays.some(
                            (dayObj) => dayObj.day === day
                          )}
                          onChange={handleCheckboxChange}
                        />
                        {day}
                        {workDays.some((dayObj) => dayObj.day === day) && (
                          <input
                            type="text"
                            className={styles.timeInput}
                            value={
                              workDays.find((dayObj) => dayObj.day === day)
                                ?.time || ""
                            }
                            onChange={(e) => handleTimeSlotChange(day, e)}
                            placeholder="Time (e.g., 9am to 12pm)"
                          />
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className={styles.rrow}>
                <button type="submit" className={styles.submitBtn}>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
  );
}
