import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useAuth } from "./AuthContext";
import HomePage from "./components/HomePage/HomePage";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import Page404 from "./components/Page404/Page404";
import ServicesPage from "./components/HomePage/services";

import CombinedPage from "./components/Dashboard/Common/Profile/profile";

import PatientDash from "./components/Dashboard/Patient/Patient";
import {
  BookAppointment,
  Feedbacks,
  MakePayment,
  MyAppointments,
  Prescriptions,
  ViewLocation,
} from "./components/Dashboard/Patient/PatientTabs";

import DoctorDash from "./components/Dashboard/Doctor/Doctor";
import {
  PatientList,
  DocAppointments,
  DocFeedbacks,
  Services,
  BillingHistory,
} from "./components/Dashboard/Doctor/DoctorTabs";

import StaffDash from "./components/Dashboard/Staff/Staff";
import {
  AvailableDoctors,
  BookAppointmentStaff,
  CancelAppointment,
  MakePaymentStaff,
} from "./components/Dashboard/Staff/StaffTabs";

import AdminDash from "./components/Dashboard/Admin/Admin";

import {
  DocList,
  HospitalList,
  ViewFeedbacks,
  GenerateStats,
  StaffList,
  VerifyUser,
  AddNew,
  EditUser,
  BookAppoinmentList,
  EnquiryList,
} from "./components/Dashboard/Admin/AdminTabs";
import Find from "./components/FindPage/Find";
import About from "./components/HomePage/aboutUsPage/index";
import ContactUs from "./components/HomePage/ContactUsPage/ContactUsPage";
import FaqPage from "./components/HomePage/FAQPage/FaqPage";
import Blogs from "./components/HomePage/BlogsPage/BlogsPage";

import {
  Ambulance,
  Bookings,
  DriverDetails,
  Pricing,
  Contacts,
} from "./components/Dashboard/Ambulance/AmbulanceTabs";

import {
  NgoDash,
  BloodDonors,
  DonorList,
  OrganDonors,
} from "./components/Dashboard/NGO/NgoTabs";

// import {
//   LabDash,
//   Lab_Booking,
//   Lab_Notification,
//   Lab_Package,
//   Lab_Prescription,
//   Lab_Report,
//   LaboratoryDashboard,
// } from "./components/Dashboard/Laboratory/LabTabs";

// import {
//   DiagnosticsDash,
//   Diagnostics_Booking,
//   Diagnostics_Notification,
//   Diagnostics_Package,
//   Diagnostics_Prescription,
//   Diagnostics_Report,
//   DiagnosticsDashboard,
// } from "./components/Dashboard/Diagnostics/DiagnosticsTabs";
import { HomeCareDash } from "./components/Dashboard/HomeCare/HomeCareTabs";
import { PharmacyDash } from "./components/Dashboard/Pharmacy/PharmacyTabs";

import {
  HospitalDash,
  HWallet,
  Cards,
  HServices,
  EDdoctors,
  HAppointments,
  HRedeem,
  HPatient,
  HFeedback,
} from "./components/Dashboard/Hospital/HospitalTabs";

import Redeem from "./components/Dashboard/Doctor/Redeem/Redeem";

function App() {
  const { userType } = useAuth();
  useEffect(() => {
    AOS.init({ duration: 650, once: true });
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/find" exact element={<Find />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" exact element={<ContactUs />} />
        <Route path="/faq" exact element={<FaqPage />} />
        <Route path="/Blogs" exact element={<Blogs />} />
        <Route path="/ServicesPage" exact element={<ServicesPage />} />

        <Route
          path="/signin"
          exact
          element={
            userType ? <Navigate to={`/dashboard/${userType}`} /> : <SignIn />
          }
        />
        <Route
          path="/signUp"
          exact
          element={
            userType ? <Navigate to={`/dashboard/${userType}`} /> : <SignUp />
          }
        />

        {/* --------------> Patient Dashboard <------------------ */}

        {userType === "Patient" && (
          <Fragment>
            <Route path="/dashboard/patient" exact element={<PatientDash />} />
            <Route
              path="/dashboard/patient/view-location"
              exact
              element={<ViewLocation />}
            />
            <Route
              path="/dashboard/patient/book-appointment"
              exact
              element={<BookAppointment />}
            />
            <Route
              path="/dashboard/patient/make-payment"
              exact
              element={<MakePayment />}
            />
            <Route
              path="/dashboard/patient/my-appointments"
              exact
              element={<MyAppointments />}
            />
            <Route
              path="/dashboard/patient/prescriptions"
              exact
              element={<Prescriptions />}
            />
            <Route
              path="/dashboard/patient/feedbacks"
              exact
              element={<Feedbacks />}
            />
          </Fragment>
        )}

        {/* ----------------> Doctor Dashboard <------------------------- */}

        {userType === "Doctor" && (
          <Fragment>
            <Route path="/dashboard/doctor" exact element={<DoctorDash />} />
            <Route
              path="/dashboard/doctor/feedbacks"
              exact
              element={<DocFeedbacks />}
            />
            <Route
              path="/dashboard/doctor/appointments"
              exact
              element={<DocAppointments />}
            />
            <Route
              path="/dashboard/doctor/patient-list"
              exact
              element={<PatientList />}
            />
            <Route
              path="/dashboard/doctor/Services"
              exact
              element={<Services />}
            />
            <Route
              path="/dashboard/doctor/wallet"
              exact
              element={<BillingHistory />}
            />
          </Fragment>
        )}

        {/* ---------------------> Staff Dashboard <------------------- */}

        {userType === "Staff" && (
          <Fragment>
            <Route path="/dashboard/staff" exact element={<StaffDash />} />
            <Route
              path="/dashboard/staff/available-doctors"
              exact
              element={<AvailableDoctors />}
            />
            <Route
              path="/dashboard/staff/book-appointment"
              exact
              element={<BookAppointmentStaff />}
            />
            <Route
              path="/dashboard/staff/cancel-appointment"
              exact
              element={<CancelAppointment />}
            />
            <Route
              path="/dashboard/staff/make-payment"
              exact
              element={<MakePaymentStaff />}
            />
          </Fragment>
        )}

        {/* --------------------> Admin Dashboard <---------------------- */}

        {userType === "Admin" && (
          <Fragment>
            <Route path="/dashboard/admin" exact element={<AdminDash />} />
            <Route
              path="/dashboard/admin/doc-list"
              exact
              element={<DocList />}
            />
            <Route
              path="/dashboard/admin/hospital-list"
              exact
              element={<HospitalList />}
            />
            <Route
              path="/dashboard/admin/staff-list"
              exact
              element={<StaffList />}
            />
            <Route
              path="/dashboard/admin/generate-stats"
              exact
              element={<GenerateStats />}
            />
            <Route
              path="/dashboard/admin/feedbacks"
              exact
              element={<ViewFeedbacks />}
            />
            <Route
              path="/dashboard/admin/verify-user"
              exact
              element={<VerifyUser />}
            />
            <Route
              path="/dashboard/admin/verify-user/addnew"
              exact
              element={<AddNew />}
            />
            <Route
              path="/dashboard/admin/verify-user/editUser"
              exact
              element={<EditUser />}
            />
            <Route
              path="/dashboard/admin/Book_Appointment_list"
              exact
              element={<BookAppoinmentList />}
            />
            <Route
              path="/dashboard/admin/Enqiry_Form_list"
              exact
              element={<EnquiryList />}
            />
          </Fragment>
        )}

        {/* --------------------> Ambulance Dashboard <---------------------- */}
        {userType === "Ambulance" && (
          <Fragment>
            <Route path="/dashboard/ambulance" exact element={<Ambulance />} />
            <Route path="/dashboard/ambulance/Pricing" element={<Pricing />} />
            <Route
              path="/dashboard/ambulance/contacts"
              element={<Contacts />}
            />
            <Route
              path="/dashboard/ambulance/DriverDetails"
              element={<DriverDetails />}
            />
            <Route
              path="/dashboard/ambulance/Bookings"
              element={<Bookings />}
            />
          </Fragment>
        )}

        {/* --------------------> Ngo Dashboard <---------------------- */}
        {userType === "NGO" && (
          <Fragment>
            <Route path="/dashboard/NGO" exact element={<NgoDash />} />
            <Route
              path="/dashboard/NGO/blood-donors"
              element={<BloodDonors />}
            />
            <Route path="/dashboard/NGO/donors-list" element={<DonorList />} />
            <Route
              path="/dashboard/NGO/organ-donors"
              element={<OrganDonors />}
            />
          </Fragment>
        )}

        {/* --------------------> Laboratory Dashboard <---------------------- */}
        {/* {userType === "Laboratory" && (
          <Fragment>
            <Route path="/dashboard/Laboratory" exact element={<LabDash />} />
            <Route
              path="/dashboard/laboratory/serives"
              exact
              element={<LaboratoryDashboard />}
            />
            <Route
              path="/dashboard/laboratory/booking"
              element={<Lab_Booking />}
            />
            <Route
              path="/dashboard/laboratory/notification"
              element={<Lab_Notification />}
            />
            <Route
              path="/dashboard/laboratory/packages"
              element={<Lab_Package />}
            />
            <Route
              path="/dashboard/laboratory/prescription"
              element={<Lab_Prescription />}
            />
            <Route
              path="/dashboard/laboratory/report"
              element={<Lab_Report />}
            />
          </Fragment>
        )} */}

        {/* --------------------> Diagnostics Dashboard <---------------------- */}
        {/* {userType === "Diagnostics" && (
          <Fragment>
            <Route
              path="/dashboard/Diagnostics"
              exact
              element={<DiagnosticsDash />}
            />
            <Route
              path="/dashboard/diagnostics/serives"
              exact
              element={<DiagnosticsDashboard />}
            />
            <Route
              path="/dashboard/diagnostics/booking"
              element={<Diagnostics_Booking />}
            />
            <Route
              path="/dashboard/diagnostics/notification"
              element={<Diagnostics_Notification />}
            />
            <Route
              path="/dashboard/diagnostics/packages"
              element={<Diagnostics_Package />}
            />
            <Route
              path="/dashboard/diagnostics/prescription"
              element={<Diagnostics_Prescription />}
            />
            <Route
              path="/dashboard/diagnostics/report"
              element={<Diagnostics_Report />}
            />
          </Fragment>
        )} */}

        {/* --------------------> Home Care Dashboard <---------------------- */}
        {userType === "Home Care" && (
          <Fragment>
            <Route
              path="/dashboard/Home Care"
              exact
              element={<HomeCareDash />}
            />
          </Fragment>
        )}
        {/* --------------------> Pharmacy Dashboard <---------------------- */}
        {userType === "Pharmacy" && (
          <Fragment>
            <Route
              path="/dashboard/Pharmacy"
              exact
              element={<PharmacyDash />}
            />
          </Fragment>
        )}

        {/* --------------------> Hospital Dashboard <---------------------- */}
        {userType === "Hospital" && (
          <Fragment>
            <Route
              path="/dashboard/Hospital"
              exact
              element={<HospitalDash />}
            />
            <Route
              path="/dashboard/Hospital/Appointments"
              exact
              element={<HAppointments />}
            />
            <Route
              path="/dashboard/Hospital/Patient-list"
              exact
              element={<HPatient />}
            />
            <Route
              path="/dashboard/Hospital/Departments"
              exact
              element={<Cards />}
            />
            <Route
              path="/dashboard/Hospital/Feedback"
              exact
              element={<HFeedback />}
            />
            <Route
              path="/dashboard/Hospital/Wallet"
              exact
              element={<HWallet />}
            />
            <Route
              path="/dashboard/Hospital/Redeem"
              exact
              element={<HRedeem />}
            />
            <Route
              path="/dashboard/Hospital/Doctors"
              exact
              element={<EDdoctors />}
            />
            <Route
              path="/dashboard/Hospital/Services"
              exact
              element={<HServices />}
            />
          </Fragment>
        )}
        {/* --------------------> Profile <---------------------- */}
        <Route path="/dashboard/profile" element={<CombinedPage />} />

        {/* --------------------> Redeem <---------------------- */}
        <Route path="/dashboard/redeem" exact element={<Redeem />} />

        {/* Unknown Routes */}
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </Router>
  );
}

export default App;
