import React, { useEffect, useState } from "react";
import styles from "./docActiveList.module.css"; // Import the CSS module
import api from "../../../../api";

const DoctorsList = () => {
  const [doctors, setDoctors] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch active doctors from the API
    const fetchDoctors = async () => {
      try {
        // const response = await fetch("https://app.aspatthealthcare.com/api/users/doctors_active");
        const response = await api.activeDoctors();
        if (response.ok) {
          const data = await response.json();
          setDoctors(data);
        } else {
          const errorData = await response.json();
          setError(errorData.errorMsg || "Failed to fetch doctors");
        }
      } catch (err) {
        setError("Error fetching doctor data");
      }
    };

    fetchDoctors();
  }, []);

  return (
    <div className={styles.container}>
      <h1>Active Doctors</h1>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.cardsContainer}>
        {doctors.length > 0 ? (
          doctors.map((doctor) => (
            <div key={doctor._id} className={styles.card}>
              <h2><strong>Doctor's name</strong>{doctor.fname}</h2>
              <p><strong>Email:</strong> {doctor.email}</p>
              <p><strong>Specialization:</strong> {doctor.speciality}</p>
              <p><strong>Department:</strong> {doctor.department}</p>
            </div>
          ))
        ) : (
          !error && <p>No active doctors available</p>
        )}
      </div>
    </div>
  );
};

export default DoctorsList;
