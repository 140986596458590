import React, { useState } from "react";
import "./Appointment_Form.css"; // Import your CSS file
import api from "../../../api"; // Import your API module
import { useAuth } from "../../../AuthContext";

const BookAppointmentForm = ({
  heading = "Enquiry Here",
  hospitalList = ["City Hospital", "General Hospital", "Healthcare Clinic"],
  doctorList = ["Dr. Smith", "Dr. John", "Dr. Adams"],
  onSubmit,
}) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    description: "",
  });
  const { setAlert, setAlertMsg, setAlertType } = useAuth();


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData); // Log submitted form data
    try {
      const res = await api.enquiry(formData); // Send the data to the backend
      console.log("Appointment booked successfully:", res.data); // You can handle success here
      setAlertMsg(res.data.msg);
      setAlertType("success");
      setAlert(true);
    } catch (error) {
      console.error("Error booking appointment:", error); // Handle error here
      // Optionally, you can show an alert or notification for the user
    }
    if (onSubmit) {
      onSubmit(formData); // Submit form data
    }
  };

  return (
    <div className="appointment-container">
      <div className="appointment-box">
        {/* Form Section */}
        <div className="form-section">
          <h2>{heading}</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Enter full name"
                  value={formData.patientName}
                  onChange={(e) =>
                    setFormData({ ...formData, fullName: e.target.value })
                  }
                  required // Make full name mandatory
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="tel"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                  required // Make phone number mandatory
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label>Email ID (Optional)</label>
                <input
                  type="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  // Email is now optional
                />
              </div>
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                placeholder="Describe your symptoms or concerns"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required
                // Description is now optional
              />
            </div>

            <button type="submit" className="submit-btn">
              Book Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookAppointmentForm;
