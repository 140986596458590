import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import HospitalListCard from "./HospitalListCard";
import SelectInput from "../../../SelectInput/SelectInput";
import styles from "./HospitalList.module.css";
import api from "../../../../api";
import { departments } from "../../Doctor/doctorDepartments";
import { useAuth } from "../../../../AuthContext";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { tabs } from "../Admin";

export default function HospitalList() {
  const { setLoader, setAlert, setAlertMsg } = useAuth();
  const [department, setDepartment] = useState("All Departments");
  const [docs, setDocs] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [theme, colorMode] = useMode();

  useEffect(() => {
    async function fetchDocs() {
      try {
        setLoader(true);
        const res = await api.hospitalsList();
        if (res.data.error) {
          setLoader(false);
          setAlertMsg(res.data.errorMsg);
          setAlert(true);
        } else {
          setLoader(false);
          setDocs(res.data);
        }
      } catch (error) {
        setLoader(false);
        setAlertMsg(error?.response?.data?.errorMsg || "An Error Occured!");
        setAlert(true);
        console.error(error);
      }
    }

    fetchDocs();
  }, [setLoader, setAlert, setAlertMsg]);

  useEffect(() => {
    if (department === "All Departments") {
      setHospitals(docs);
    } else {
      const filteredData = docs.filter((doc) => doc.department === department);
      setHospitals(filteredData);
    }
  }, [department, docs]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* <Navbar /> */}
        <Sidebar tabs={tabs} />
        <div className={styles.mainContent}>
            <Topbar />
          </div>
        <div className={styles.container}>
          <div className={styles.filterContainer}>
            <SelectInput
              label=""
              value={department}
              setValue={setDepartment}
              options={departments}
            />
          </div>
          <div className={styles.cardContainer}>
            <Grid container spacing={3}>
              {hospitals.map((hospital, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <HospitalListCard hospital={hospital} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
