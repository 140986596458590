import React, { useEffect, useState } from "react";
import styles from "./hospitalActiveList.module.css"; // Import the CSS module
import api from "../../../../api";

const HospitalsList = () => {
  const [hospitals, setHospitals] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch active hospitals from the API
    const fetchHospitals = async () => {
      try {
        // const response = await fetch("https://app.aspatthealthcare.com/api/users/hospitals_active");
        const response = await api.activeHospitals();
        console.log('Active Hospitals:', hospitals);
        if (response.ok) {
          const data = await response.json();
          setHospitals(data);
        } else {
          const errorData = await response.json();
          setError(errorData.errorMsg || "Failed to fetch hospitals");
        }
      } catch (err) {
        setError("Error fetching hospital data");
      }
    };

    fetchHospitals();
  }, []);

  return (
    <div className={styles.container}>
      <h1>Active Hospitals</h1>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.cardsContainer}>
        {hospitals.length > 0 ? (
          hospitals.map((hospital) => (
            <div key={hospital._id} className={styles.card}>
              <h2><strong>Hospital Name:</strong>{hospital.fname}</h2>
              <p><strong>Email:</strong> {hospital.email}</p>
              <p><strong>Contact:</strong> {hospital.contact}</p>
            </div>
          ))
        ) : (
          !error && <p>No active hospitals available</p>
        )}
      </div>
    </div>
  );
};

export default HospitalsList;
