import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import api from "../../../../api"; // Import your API module

export default function HospitalListCard(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(props.hospital.status || "deactive"); // Initialize with hospital's current status
  const [loading, setLoading] = useState(false); // Loading state for the status update

  const handleEdit = () => {
    navigate({
      pathname: "/dashboard/admin/verify-user/editUser",
      search: `?${createSearchParams({
        uid: props.hospital.uid,
      })}`,
    });
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus); // Optimistic update

    // Make API call to update status in the backend using api.js
    try {
      setLoading(true); // Start loading

      const response = await api.statusChange({
        uid: props.hospital.uid,
        status: newStatus,
      });

      console.log("Status updated:", response.data);
    } catch (error) {
      console.error(
        "Error updating status:",
        error.response?.data?.message || error.message
      );
      // Revert status change if there is an error
      setStatus(status === "active" ? "deactive" : "active");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <Card
      sx={{
        maxWidth: "100%",
        textAlign: "center",
        border: `1px solid ${status === "active" ? "green" : "red"}`, // Dynamic border color
        backgroundColor: status === "active" ? "#e8f5e9" : "#ffebee", // Active or inactive background
      }}
      variant="outlined"
    >
      <CardContent>
        {/* Dropdown on top-right for selecting status */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={handleStatusChange}
              disabled={loading}
              sx={{ color: "black" }}
            >
              <MenuItem value="active">
                <FiberManualRecordIcon
                  sx={{ fontSize: 12, color: "green", mr: 1 }}
                />
                Active
              </MenuItem>
              <MenuItem value="deactive">
                <FiberManualRecordIcon
                  sx={{ fontSize: 12, color: "red", mr: 1 }}
                />
                Inactive
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <br />
        <Typography variant="h2" color="black" sx={{ mb: 3 }}>
          {` ${props.hospital.fname} `}
        </Typography>
        <Typography sx={{ mb: 0.5, fontSize: "1.8rem" }}>
          {props.hospital.speciality}
        </Typography>
        <Typography sx={{ mb: 0.5, fontSize: "1.8rem" }}>
          {`Department of ${props.hospital.department}`}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 0.8 }}>
          {props.hospital.workDays
            .map((dayObj) => `${dayObj.day}: ${dayObj.time}`)
            .join(" | ")}
        </Typography>
        <Typography sx={{ mb: 1.5, fontSize: "0.9rem" }}>
          {props.hospital.time}
        </Typography>
        <br />
        <CardActions disableSpacing sx={{ justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="warning"
            size="small"
            endIcon={<EditIcon />}
            onClick={handleEdit}
          >
            Edit
          </Button>
          <Button sx={{ pointerEvents: "none", color: "#000" }}>
            {`₹ ${props.hospital.fee}/-`}
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}
