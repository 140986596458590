import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import Header from "../../Common/Heading/Heading";
import styles from "./bookAppointmentList.module.css";
import { tabs } from "../Admin";
import Sidebar from "../../Common/Sidebar/Sidebar";
import Topbar from "../../Common/Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";
import EditIcon from "@mui/icons-material/Edit";
import { CssBaseline, ThemeProvider, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../api";

const BookAppoinmentList = () => {
  const [theme, colorMode] = useMode();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const colors = tokens(theme.palette.mode);

  const columns = [
    { field: "id", headerName: "Booking ID", flex: 0.5 },
    { field: "patientName", headerName: "Patient Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.75 },
    { field: "appoinmentDate", headerName: "Appointment Date", flex: 1 },
    { field: "hospitalName", headerName: "Hospital Name", flex: 1 },
    { field: "doctorName", headerName: "Doctor Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "appoinmentStatus", headerName: "Appoinment Status", flex: 1 },
    { field: "comment", headerName: "Comment", flex: 1 }, // Add comment field to the table
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          <Tooltip title="Edit">
            <Button
              variant="contained"
              style={{
                backgroundColor: "green",
                color: "white",
                marginRight: "8px",
                marginBottom: "8px",
              }}
              onClick={() => handleEdit(params.row)}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleEdit = (row) => {
    setEditData(row);
    setOpenEdit(true);
  };

  const handleUpdate = async () => {
    try {
      // await axios.patch(
      //   `https://app.aspatthealthcare.com/api/v1/admin/bookappoinment/${editData.id}`,
      //   editData
      // );
      await api.updateAppointment(editData.id, editData); // Use the api method
      setData(data.map((item) => (item.id === editData.id ? editData : item))); // Update local data
      setOpenEdit(false);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAppointments();
        const formattedData = response.data.map((row) => ({
          ...row,
          id: row._id,
        }));
        setData(formattedData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.container}>
          <Sidebar tabs={tabs} />
          <div className={styles.mainContent}>
            <Topbar />
          </div>
          <Box m="20px" marginLeft="275px">
            <Header
              title="Patient List"
              subtitle="List of All Bookings and Their Details"
            />
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#1a385a",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#1a385a",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={data}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                getRowId={(row) => row._id}
              />
            </Box>
          </Box>

          {/* Edit Modal */}

          <Dialog
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            PaperProps={{
              sx: { backgroundColor: "#121925" }, // Apply the custom CSS class
            }}
          >
            <DialogTitle sx={{ fontSize: "1.5rem" }}>Edit Booking</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="normal"
                label="Patient Name"
                type="text"
                fullWidth
                variant="outlined"
                value={editData.patientName || ""}
                onChange={(e) =>
                  setEditData({ ...editData, patientName: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={editData.email || ""}
                onChange={(e) =>
                  setEditData({ ...editData, email: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Phone Number"
                type="text"
                fullWidth
                variant="outlined"
                value={editData.phoneNumber || ""}
                onChange={(e) =>
                  setEditData({ ...editData, phoneNumber: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Appointment Date"
                type="date"
                fullWidth
                variant="outlined"
                value={editData.appoinmentDate || ""}
                onChange={(e) =>
                  setEditData({ ...editData, appoinmentDate: e.target.value })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="dense"
                label="Hospital Name"
                type="text"
                fullWidth
                variant="outlined"
                value={editData.hospitalName || ""}
                onChange={(e) =>
                  setEditData({ ...editData, hospitalName: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Doctor Name"
                type="text"
                fullWidth
                variant="outlined"
                value={editData.doctorName || ""}
                onChange={(e) =>
                  setEditData({ ...editData, doctorName: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                value={editData.description || ""}
                onChange={(e) =>
                  setEditData({ ...editData, description: e.target.value })
                }
              />
              <TextField
                margin="dense"
                label="Appointment Status"
                select
                fullWidth
                variant="outlined"
                value={editData.appoinmentStatus || ""}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    appoinmentStatus: e.target.value,
                  })
                }
              >
                <MenuItem value="PENDING">Pending</MenuItem>
                <MenuItem value="COMPLETED">Completed</MenuItem>
                <MenuItem value="REJECTED">Rejected</MenuItem>
              </TextField>
              <TextField
                margin="dense"
                label="Comment"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                rows={4} // Multi-line text area
                value={editData.comment || ""}
                onChange={(e) =>
                  setEditData({ ...editData, comment: e.target.value })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEdit(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdate} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default BookAppoinmentList;
