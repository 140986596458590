import React from "react";

const About = () => {
  return (
    <div>
      <section className="why-choose section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>
                  Find the right care, doctor, and hospital right at your
                  fingertips
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="choose-right">
              <div className="video-image">
                <div className="promo-video">
                  <div className="waves-block">
                    <div className="waves wave-1"></div>
                    <div className="waves wave-2"></div>
                    <div className="waves wave-3"></div>
                  </div>
                </div>
                <img src="img/video-bg.jpg" alt="Your Description" />
                <a
                  href="https://www.youtube.com/watch?v=kXLwD7q3HmI"
                  className="video video-popup mfp-iframe"
                >
                  <i className="fa fa-play"></i>
                </a>
              </div>
              <div className="video-image">
                <div className="promo-video">
                  <div className="waves-block">
                    <div className="waves wave-1"></div>
                    <div className="waves wave-2"></div>
                    <div className="waves wave-3"></div>
                  </div>
                </div>
                <img src="/image_vid.png" alt="Your Description" />
                <a
                  href="https://www.youtube.com/watch?v=kXLwD7q3HmI"
                  className="video video-popup mfp-iframe"
                >
                  <i className="fa fa-play"></i>
                </a>
              </div>
            </div>
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              <button>Watch Playlist</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
