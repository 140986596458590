import React from "react";
import "./icofont.css";
import "./HomePage.css";
import "./normalize.css";
import "./responsive.css";
import "./font-awesome.min.css";
import Header from "./sections/Header";
import Footer from "./sections/Footer";
import About from "./sections/About";
import ContactUs from "./sections/ContactUs";
import LandingPage from "./sections/LandingPage";
import Team from "./sections/Team";
import Testimonial from "./sections/Testimonial";
import CallToAction from "./sections/CallToAction/callToAction";
import ServicesThree from "./ServicesThree";
import VerticalCarousel from "./sections/VerticalCarousel";
import SectionSix from "./sections/sectionSix";
import Cards from "./sections/cards";
import Consultations from "./sections/Consulations";
import NoCostEMI from "./sections/NoCostEMI";
import ServiceSection from "./sections/serviceSection";
import OurPartners from "./sections/OurPartners/OurPartners";

const HomePage = () => {
  const DiseasesSection = {
    heading: "Diseases",
    subHeading: "Treatment on every diseases",
    buttonText1: "View All",
    buttonText2: "Enquiry",
    buttonUrl1: "mithun",
    buttonUrl2: "kumbhare",
    data: [
      { icon: "", src: "services/cough.png", heading: "Cough", link: "/cough" },
      {
        icon: "",
        src: "services/head.png",
        heading: "Headache",
        link: "/headache",
      },
      {
        icon: "",
        src: "services/back.png",
        heading: "Backache",
        link: "/backache",
      },
      {
        icon: "",
        src: "services/fever.png",
        heading: "Fever",
        link: "/Fever",
      },
      {
        icon: "",
        src: "services/tooth.png",
        heading: "Toothache",
        link: "/toothache",
      },
      // { icon: "fas fa-hospital", heading: "Hospital", link: "/hospital" },
      // {
      //   icon: "fas fa-stethoscope",
      //   heading: "Diagnostics",
      //   link: "/diagnostics",
      // },
    ],
  };
  const SurgeriesSection = {
    heading: "Surgeries",
    subHeading: "17,000+ successful surgeries performed",
    buttonText1: "View All",
    buttonText2: "Enquiry",
    buttonUrl1: "wew",
    buttonUrl2: "fdfdf",
    data: [
      { icon: "", src: "services/heart.png", heading: "CABG", link: "/cabg" },
      {
        icon: "",
        src: "services/neuro.png",
        heading: "Neurosurgery",
        link: "/neurosurgery",
      },
      {
        icon: "",
        src: "services/vasectomy.png",
        heading: "Vasectomy",
        link: "/vasectomy",
      },
      {
        icon: "",
        src: "services/hysterectomy.png",
        heading: "Hysterectomy",
        link: "/ambulance",
      },
      {
        icon: "",
        src: "services/arthroscopy.png",
        heading: "Arthroscopy",
        link: "/arthroscopy",
      },
      // {
      //   icon: "fas fa-stethoscope",
      //   heading: "Diagnostics",
      //   link: "/diagnostics",
      // },
      // { icon: "fas fa-clinic-medical", heading: "Clinic", link: "/clinic" },
      // { icon: "fas fa-syringe", heading: "Vaccination", link: "/vaccination" },
      // { icon: "fas fa-wheelchair", heading: "Wheelchair", link: "/wheelchair" },
      // { icon: "fas fa-heart", heading: "Cardiology", link: "/cardiology" },
      // { icon: "fas fa-x-ray", heading: "X-Ray", link: "/x-ray" },
    ],
  };
  return (
    <div>
      <Header />
      <LandingPage />
      <Cards />
      <CallToAction />
      <div style={{ marginBottom: "60px" }}></div>
      <ServicesThree {...DiseasesSection} />
      <ServicesThree {...SurgeriesSection} />
      <Team />
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontSize: "5rem" }}>Our specialities</h2>
        <p style={{ fontSize: "3rem", marginTop: "10px" }}>
          We Are Specialize In Medical Diagnostics
        </p>
      </div>
      <VerticalCarousel />
      {/* <FunFact />
      <Services /> */}
      <Consultations />
      <ServiceSection />
      <About />
      {/* <ServicesTwo /> */}
      <Testimonial />
      <NoCostEMI />
      <SectionSix />
      {/* <BlogSection />
      <Enquiry /> */}

      <ContactUs />

      <Footer />
    </div>
  );
};

export default HomePage;
