// CombinedProfile.js
import { useLocation } from "react-router-dom";

import React, { Suspense, useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';  // Corrected import path
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CssBaseline, ThemeProvider } from '@mui/material';

import styles from "./profile.module.css";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import { ColorModeContext, useMode } from "../../../../theme";

// import {tabs} from "../../Doctor/Doctor"
import { loadConfig } from "./configLoader";
import EditUserForm from "../EditUserForm/EditUserForm";

const CombinedPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const dashboardType = searchParams.get("dashboard");
  
    const [sidebarConfig, setSidebarConfig] = useState({
      tabs: [],
    });

    const [theme, colorMode] = useMode();
  
    useEffect(() => {
      const fetchConfig = async () => {
        console.log('Fetching config...'); // Log before fetching
        try {
          const config = await loadConfig(dashboardType);
          console.log('Loaded Sidebar Config:', config);
          setSidebarConfig({
            tabs: config.tabs || [],
          });
        } catch (error) {
          console.error('Error loading sidebar configuration:', error);
        }
        console.log('Config fetched and set.'); // Log after fetching
      };
    
      if (dashboardType) {
        fetchConfig();
      } else {
        console.log('No dashboardType found.');
      }
    }, [dashboardType]);
  
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Sidebar tabs={tabs} /> */}
        {sidebarConfig.tabs.length > 0 ? (
          <Sidebar tabs={sidebarConfig.tabs} />
        ) : (
          <CircularProgress /> // Show loader if sidebar tabs are not yet loaded
        )}
        <div className={styles.mainContent}>
          <Topbar />
        </div>
        <EditUserForm />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default CombinedPage;
