import React from "react";
import styles from "./cards.module.css";

const Cards = () => {
  // Dummy data with 14 items
  const cardData = [
    { id: 1, name: "hospital", image: "/hospital.png", link: "/find" },
    {
      id: 2,
      name: "Diagnostic",
      image: "/diagnostic.png",
      link: "/diagnostic",
    },
    {
      id: 3,
      name: "Laboratory",
      image: "/laboratory.png",
      link: "/laboratory",
    },
    { id: 4, name: "Ayurveda", image: "/ayurveda.png", link: "/ayurveda" },
    {
      id: 5,
      name: "Homeopathy",
      image: "/homeopathy.png",
      link: "/homeopathy",
    },
    {
      id: 6,
      name: "Physiotherapy",
      image: "/physiotherapy.png",
      link: "/physiotherapy",
    },
    { id: 7, name: "Yoga & wellness", image: "/yoga.png", link: "/yoga" },
    { id: 8, name: "Home care", image: "/homecare.png", link: "/homecare" },
    { id: 9, name: "Dental", image: "/dental.png", link: "/dental" },
    {
      id: 10,
      name: "Deaddiction & Rehabilitation",
      image: "/deaddiction.png",
      link: "/deaddiction",
    },
    { id: 11, name: "Ambulance", image: "/ambulance.png", link: "/ambulance" },
    { id: 12, name: "Pharmacy", image: "/pharmacy.png", link: "/pharmacy" },
    { id: 13, name: "NGO", image: "/ngo.png", link: "/ngo" },
    { id: 14, name: "Insurance", image: "/insurance.png", link: "/insurance" },
  ];

  return (
    <div className={styles.cardContainer}>
      {cardData.map((card) => (
        <a key={card.id} href={card.link} className={styles.cardLink}>
          <div className={styles.card}>
            <img
              src={card.image}
              alt={card.name}
              className={styles.cardImage}
            />
            <p className={styles.cardName}>{card.name}</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Cards;
