import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BookAppointment from "./Book_Appointment";

const Header = () => {
  const location = useLocation(); // Get the current route

  // State to track if the user is logged in
  const [IsLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenPopup = (event) => {
    event.preventDefault(); // Prevent default anchor behavior
    setIsOpen(true); // Show popup immediately
  };

  const handleClosePopup = () => {
    setIsOpen(false);
  };

  // Simulating user login status
  useEffect(() => {
    const userLoggedIn = localStorage.getItem("userToken");
    setIsLoggedIn(!!userLoggedIn);
  }, []);

  // Function to check if the current route matches the link
  const isActive = (pathname) => location.pathname === pathname;

  return (
    <header className="header">
      <div className="topbar">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-3 col-md-5 col-12">
              <a href="/">
                <img className="head-logo" src="/Logo-1.png" alt="#" />
              </a>
            </div>

            <div className="S-L">
              <div style={{ marginLeft: "-20px", marginTop: "15px" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    padding: "5px",
                    borderRadius: "4px",
                    width: "100%",
                    border: "1px solid #ccc",
                  }}
                >
                  <SearchIcon
                    style={{
                      color: "#000",
                      marginRight: "8px",
                      fontSize: "24px",
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "16px",
                    }}
                    onChange={() => console.log("onChange")}
                  />
                </label>
              </div>

              <div style={{ marginLeft: "30px", marginTop: "15px" }}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "white",
                    padding: "5px",
                    borderRadius: "4px",
                    width: "80%",
                    border: "1px solid #ccc",
                  }}
                >
                  <LocationOnIcon
                    style={{
                      color: "#000",
                      marginRight: "8px",
                      fontSize: "24px",
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Mumbai"
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "16px",
                    }}
                    onChange={() => console.log("onChange")}
                  />
                </label>
              </div>
            </div>

            <div className="col-lg-3 col-md-5 col-12">
              <ul className="top-contact">
                <li>
                  <div className="col-lg-2 col-12">
                    <div className="get-quote">
                    <button className="header-btn" onClick={handleOpenPopup}>
                      Book Appointment
                    </button>
                    {isOpen && <BookAppointment onClose={handleClosePopup} immediate={true} />}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="col-lg-2 col-12">
                    <div className="get-quote">
                      <a href="/Contact" className="header-btn">
                        Enquiry Here
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="header-inner">
        <div className="container">
          <div className="inner">
            <div className="row justify-content-center">
              {" "}
              {/* Centered Navigation */}
              <div className="col-lg-7 col-md-9 col-12">
                <div className="main-menu">
                  <nav className="navigation">
                    <ul className="nav menu">
                      <li className={isActive("/") ? "active" : ""}>
                        <a href="/">Home</a>
                      </li>
                      <li className={isActive("/find") ? "active" : ""}>
                        <a href="/find">Find</a>
                      </li>
                      <li className={isActive("/services") ? "active" : ""}>
                        <a href="/services">
                          Services
                          <i className="icofont-rounded-down" />
                        </a>
                        <ul className="dropdown">
                          <li>
                            <a href="/">
                              Diagnostics & Laboratory
                              <i className="icofont-rounded-right" />
                            </a>
                            <ul className="dropdown-right">
                              <li>
                                <a href="index.html">Pathology</a>
                              </li>
                              <li>
                                <a href="index.html"> Radiology</a>
                              </li>
                              <li>
                                <a href="index.html">Cardiology</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">
                              Therapeutic & Rehabilitation
                              <i className="icofont-rounded-right" />
                            </a>
                            <ul className="dropdown-right">
                              <li>
                                <a href="index.html">Physiotherapy</a>
                              </li>
                              <li>
                                <a href="index.html">Palliative Care</a>
                              </li>
                              <li>
                                <a href="index.html">Home Care</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">
                              Medical Specialties
                              <i className="icofont-rounded-right" />
                            </a>
                            <ul className="dropdown-right">
                              <li>
                                <a href="index.html">General Medicine</a>
                              </li>
                              <li>
                                <a href="index.html"> Pediatrics</a>
                              </li>
                              <li>
                                <a href="index.html">Gynecology</a>
                              </li>
                              <li>
                                <a href="index.html">Neurology</a>
                              </li>
                              <li>
                                <a href="index.html">Orthopedics</a>
                              </li>
                              <li>
                                <a href="index.html">Dermatology</a>
                              </li>
                              <li>
                                <a href="index.html">Psychiatry</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">
                              Surgical & Emergency
                              <i className="icofont-rounded-right" />
                            </a>
                            <ul className="dropdown-right">
                              <li>
                                <a href="index.html">Surgery</a>
                              </li>
                              <li>
                                <a href="index.html">Ambulance</a>
                              </li>
                              <li>
                                <a href="index.html">Trauma Care</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">
                              Pharmaceutical & Nutrition
                              <i className="icofont-rounded-right" />
                            </a>
                            <ul className="dropdown-right">
                              <li>
                                <a href="index.html">Pharmacy</a>
                              </li>
                              <li>
                                <a href="index.html">Nutrition & Dietetics</a>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <a href="/">
                              Specialized Care
                              <i className="icofont-rounded-right" />
                            </a>
                            <ul className="dropdown-right">
                              <li>
                                <a href="index.html">Oncology</a>
                              </li>
                              <li>
                                <a href="index.html">Gastroenterology</a>
                              </li>
                              <li>
                                <a href="index.html">Nephrology</a>
                              </li>
                              <li>
                                <a href="index.html">Urology</a>
                              </li>
                              <li>
                                <a href="index.html">ENT (Ear, Nose, Throat)</a>
                              </li>
                              <li>
                                <a href="index.html">Ophthalmology</a>
                              </li>
                              <li>
                                <a href="index.html">Dentistry</a>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li className={isActive("/about") ? "active" : ""}>
                        <a href="/about">About Us</a>
                      </li>
                      <li className={isActive("/blogs") ? "active" : ""}>
                        <a href="/blogs">Blogs</a>
                      </li>
                      <li className={isActive("/faq") ? "active" : ""}>
                        <a href="/faq">Active List</a>
                      </li>
                      <li className={isActive("/contact") ? "active" : ""}>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
