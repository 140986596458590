import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import styles from "./SignUp.module.css";
import api from "../../api";
import { useAuth } from "../../AuthContext";
import AOS from "aos";

const options = [
  "Select User Type",
  "Diagnostics",
  "Pharmacy",
  "Hospital",
  "Doctor",
  "NGO",
  "Home Care",
  "Laboratory",
  "Ambulance",
  "Patient",
];


export default function SignUp() {
  const { setLoader, setAlert, setAlertMsg, setAlertType } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(options[0]);
  const [department, setDepartment] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [fname, setFname] = useState("");
  // const [lname, setLname] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [currentlocation, setCurrentLocation] = useState("");
  const [address, setAddredss] = useState("");
  const [insurance, setInsurance] = useState("");
  const [medicalcard, setMedicalCard] = useState("");
  const [governmentjob, setGovernmentJob] = useState("");
  const [password, setPassword] = useState("");


  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (user === "Select User Type") {
    //   setAlertMsg("Please select a user type.");
    //   setAlertType("error");
    //   setAlert(true);
    //   return;
    // }

    const postData = {
      userType: user,
      fname,
      mobilenumber,
      email,
      dateofbirth,
      currentlocation,
      address,
      insurance,
      governmentjob,
      medicalcard,
      password,


      department,
      speciality,
    };

    try {
      setLoader(true);
      const res = await api.signup(postData);
      if (res.data.error) {
        setLoader(false);
        setAlertMsg(res.data.errorMsg);
        setAlert(true);
      } else {
        setLoader(false);
        setUser(options[0]);
        setDepartment("");
        setSpeciality("");
        setFname("");
        setMobileNumber("");
        setEmail("");
        setDateOfBirth("");
        setCurrentLocation("");
        setAddredss("");
        setInsurance("");
        setGovernmentJob("");
        setMedicalCard("");
        setPassword("");

        setAlertMsg(res.data.msg);
        setAlertType("success");
        setAlert(true);

        navigate("/signin");
      }
    } catch (error) {
      setLoader(false);
      setAlertMsg(error.response?.data?.errorMsg || "An error occurred!");
      setAlert(true);
      console.error(error);
    }
  };
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          {/* Left Column */}
          <div className={styles.col} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <img src="/signup.png" alt="doctor" className={styles.image} draggable="false" />
          </div>
          {/* Right Column - Form */}
          <div className={styles.col}>
            <div className={styles.maincontainer}>
              <Typography component="h1" variant="h3" className={styles.header} data-aos="zoom-in">
                Create An Account
              </Typography>
              <Typography variant="h6" data-aos="zoom-in">
                Fill up the details to create account
              </Typography>

              <form className={styles.form} onSubmit={handleSubmit}>

                {/* Name Fields */}
                <div className={styles.formGroup} data-aos="zoom-in">
                  <input
                    type="text"
                    name="firstName"
                    required
                    id="firstName"
                    value={fname}
                    placeholder="Type your Name"
                    onChange={(event) => setFname(event.target.value)}
                  />
                </div>
                <div className={styles.formGroup} data-aos="zoom-in">
                  <input
                    type="number"
                    name="numbe"
                    required
                    id="number"
                    value={mobilenumber}
                    placeholder="Mobile Number"
                    onChange={(event) => setMobileNumber(event.target.value)}
                  />
                </div>
                

                {/* Email Field */}
                <div className={styles.formGroup} data-aos="zoom-in">
                  <input
                    required
                    id="email"
                    type="email"
                    value={email}
                    autoComplete="email"
                    placeholder="Enter your email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                {/* Date of Birth Field */}
                <div className={styles.formGroup} data-aos="zoom-in">
                  <input
                    type="date"
                    name="Date of Birth"
                    id="Date of Birth"
                    value={dateofbirth}
                    placeholder="Date of Birth"
                    onChange={(event) => setDateOfBirth(event.target.value)}
                  />
                </div>
                <div className={styles.formRow}>
                  {/* Location Field */}
                  <div className={styles.formGroup} data-aos="zoom-in">
                    <input
                      type="text"
                      name="CurrentLocation"
                      id="Current Location"
                      value={currentlocation}
                      placeholder="Current Location"
                      onChange={(event) => setCurrentLocation(event.target.value)}
                    />
                  </div>
                  {/* Address Field */}
                  <div className={styles.formGroup} data-aos="fade-left">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      value={address}
                      placeholder="+ Add Address"
                      onChange={(event) => setAddredss(event.target.value)}
                    />
                  </div>
                </div>
                {/* Insurance Field */}
                <div className={styles.formGroup} data-aos="fade-left">
                  <input
                    name="insurance"
                    type="insurance"
                    id="insurance"
                    value={insurance}
                    placeholder="Insurance If Any"
                    onChange={(event) => setInsurance(event.target.value)}
                  />
                </div>
                <div className={styles.formRow}>
                  {/* Government Job Field */}
                  <div className={styles.formGroup} data-aos="fade-left">
                    <input
                      type="text"
                      name="governmentJob"
                      id="Goverment Job"
                      value={governmentjob}
                      placeholder="Government Job"
                      onChange={(event) => setGovernmentJob(event.target.value)}
                    />
                  </div>
                  {/* Medical Card Field */}
                  <div className={styles.formGroup} data-aos="fade-left">
                    <input
                      type="text"
                      name="medicalcard"
                      id="medicalcard"
                      value={medicalcard}
                      placeholder="Medical Card"
                      onChange={(event) => setMedicalCard(event.target.value)}
                    />
                  </div>
                </div>

                {/* Password Field */}
                <div className={styles.formGroup}>
                  <input
                    required
                    name="password"
                    type="password"
                    id="password"
                    value={password}
                    placeholder="Create a Password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div>
                  <input type="checkbox" required />
                  <span>I agree with Terms and Privacy (mandatory)</span>
                </div>
                {/* Submit Button */}
                <button type="submit" className={styles.submitButton} >
                  Sign Up
                </button>

                <Link to="/signin" className={styles.linkBtn}>
                  {"Already have an account? Sign In"}
                </Link>
              </form>
            </div>
          </div>

          {/* Right Column - Image */}
          {/* <div className={styles.col}>
            <img
              src={userImages[user.replace(/ /g, "")] || "/medicine.svg"}
              alt={user}
              className={styles.userTypeImage}
            />
          </div> */}
          
        </div>
      </div>
    </section>
  );
}
