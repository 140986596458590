import React from "react";
import "./callToAction.css";
const callToAction = () => {
  return (
    <div className="">
      <section className="calltoaction">
        <div className="background-container">
          <span className="background-overlay"></span>
          <div className="background-cover container">
            <div className="line-heading">
              <div className="line-heading-left">
                <h2>Consultation</h2>
                <h4>with top doctors 24/7</h4>
              </div>

              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default callToAction;
