import React, { useEffect } from "react";
import styles from "./serviceSection.module.css";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles

const ServiceSection = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a custom duration
  }, []);
  return (
    <div className={styles.container}>
      {/* First Section: About Us */}
      <section className={styles.firstSection} data-aos="zoom-in"></section>

      {/* Second Section: Our Mission */}
      <section className={styles.secondSection}>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>Our Mission</h2>
          <p className={styles.p}>
            Our mission is to provide exceptional, compassionate care to every
            individual we serve. We believe in treating not just the illness,
            but the whole person, and we are dedicated to making a positive
            impact on the lives of our patients and their families.
          </p>
          <p className={styles.p}>
            Our team is comprised of highly skilled and dedicated professionals,
            including doctors, nurses, specialists, and support staff, all
            committed to delivering the highest standard of care.
          </p>
          <p className={styles.p}>
            Our facility is equipped with state-of-the-art technology and
            follows the latest advancements in medical practices to ensure you
            receive the best possible treatment. But beyond our expertise and
            technology, we pride ourselves on our human touch, understanding
            that healthcare is as much about empathy and connection as it is
            about medical knowledge.
          </p>
        </div>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/signIn.png" alt="Our Mission" />
        </div>
      </section>

      {/* Third Section: What Sets Us Apart */}
      <section className={styles.thirdSection}>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/about2.png" alt="What Sets Us Apart" />
        </div>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>What Sets Us Apart</h2>
          <p className={styles.p}>
            Patient-Centered Care: We put you at the heart of everything we do.
            From your first visit to your ongoing care, we listen to your needs
            and preferences, ensuring that your healthcare journey is as
            comfortable and effective as possible.
          </p>
          <p className={styles.p}>
            Expert Team: Our team of medical professionals includes
            [specializations or notable experts], who are leaders in their
            fields. Their combined experience and commitment to continuing
            education ensure that you receive the most up-to-date and effective
            treatments available.
          </p>
        </div>
      </section>
      {/* fourth Section: Our Mission */}
      <section className={styles.secondSection}>
        <div className={styles.textBlock} data-aos="zoom-in">
          <h2>Our Mission</h2>
          <p className={styles.p}>
            Our mission is to provide exceptional, compassionate care to every
            individual we serve. We believe in treating not just the illness,
            but the whole person, and we are dedicated to making a positive
            impact on the lives of our patients and their families.
          </p>
          <p className={styles.p}>
            Our team is comprised of highly skilled and dedicated professionals,
            including doctors, nurses, specialists, and support staff, all
            committed to delivering the highest standard of care.
          </p>
          <p className={styles.p}>
            Our facility is equipped with state-of-the-art technology and
            follows the latest advancements in medical practices to ensure you
            receive the best possible treatment. But beyond our expertise and
            technology, we pride ourselves on our human touch, understanding
            that healthcare is as much about empathy and connection as it is
            about medical knowledge.
          </p>
        </div>
        <div className={styles.imageBlock} data-aos="zoom-in">
          <img src="/signIn.png" alt="Our Mission" />
        </div>
      </section>
    </div>
  );
};

export default ServiceSection;
